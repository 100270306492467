import moment from 'moment';
import QrcodeVue from 'qrcode.vue';
import { mapActions, mapState } from 'vuex';
import AddDocumentDialog from '@/components/contract/read/elements/AddDocumentDialog/AddDocumentDialog.vue';
import AddVersionDialog from '@/components/contract/read/elements/AddVersionDialog/AddVersionDialog.vue';
import DeleteVersionBtn from '@/components/contract/read/elements/DeleteVersionBtn/DeleteVersionBtn.vue';
import ContractSheetDialog from '@/components/contract/create/elements/ContractSheetDialog/ContractSheetDialog.vue';
import AddCommentDialog from '@/components/contract/read/elements/AddCommentDialog/AddCommentDialog.vue';
import AddDirectAccessDialog from '@/components/contract/read/elements/AddDirectAccessDialog/AddDirectAccessDialog.vue';
import ResponsiblesSection from '@/components/contract/read/elements/ResponsiblesSection/ResponsiblesSection.vue';
import DeleteDocumentBtn from '@/components/contract/read/elements/DeleteDocumentBtn/DeleteDocumentBtn.vue';
import DeleteFileBtn from '@/components/contract/read/elements/DeleteFileBtn/DeleteFileBtn.vue';
import DisableContractBtn from '@/components/contract/read/elements/DisableContractBtn/DisableContractBtn.vue';
import UpdateTitleDialog from '@/components/contract/elements/UpdateTitleDialog/UpdateTitleDialog.vue';

export default {
	name: 'ReadContract',
	props: {},
	data: () => ({
		searchForm: {
			more: false,
		},
		document: { file: [] },
		arr: [],
		isLoading: false,
		result_selected: null,
		documentDialog: false,
		versionDialog: false,
		contractDialog: false,
		commentDialog: false,
		directAccessDialog: false,

		sheetAddendumDialog: false,
		sheetAddendumFields: [],
		currentDocument: null,

		documents_headers: [
			{
				text: 'Archivo',
				value: 'file',
				sortable: false,
				align: 'left',
				class: 'primary--text',
			},
			{
				text: 'Versión',
				value: 'version',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Documento actual',
				value: 'flag_disabled_current',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Documento antiguo',
				value: 'flag_disabled_old',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Agregado',
				value: 'created_at',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Tipo',
				value: 'type',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Añadido por:',
				value: 'user_id',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Comentarios',
				value: 'comment',
				sortable: false,
				align: 'right',
				class: 'primary--text',
			},
			{
				text: 'Ficha Contractual',
				value: 'actions',
				sortable: false,
				align: 'right',
				class: 'primary--text',
			},
			{
				text: '',
				value: 'delete',
				sortable: false,
				align: 'right',
				class: 'primary--text',
			},
		],

		direct_access_headers: [
			{
				text: 'Acceso',
				value: 'type',
				sortable: false,
				align: 'left',
				class: 'primary--text',
			},
			{
				text: 'Página',
				value: 'page',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
		],

		version_headers: [
			{
				text: 'Archivo',
				value: 'file',
				sortable: false,
				align: 'left',
				class: 'primary--text',
			},
			{
				text: 'Versión',
				value: 'version',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Agregado',
				value: 'created_at',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Añadido por:',
				value: 'user_id',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Comentarios',
				value: 'comment',
				sortable: false,
				align: 'right',
				class: 'primary--text',
			},
			{
				text: '',
				value: 'delete',
				sortable: false,
				align: 'right',
				class: 'primary--text',
			},
		],

		comments_headers: [
			{
				text: 'Añadido por:',
				value: 'user_id',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Fecha',
				value: 'created_at',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Comentario',
				value: 'comment',
				sortable: false,
				align: 'right',
				class: 'primary--text',
			},
		],
	}),
	computed: {
		...mapState('contract', ['contract']),
		...mapState('security', ['usersActive']),
		...mapState('auth', ['activities']),
		contractFile() {
			if (
				this.contract?.contractVersions &&
				this.contract?.contractVersions.length > 0
			) {
				const lastContractVersion = this.contract?.contractVersions.length - 1;
				return this.contract?.contractVersions[lastContractVersion].file;
			} else {
				return this.contract.file;
			}
		},

		isAdmin() {
			let isAdmin = false;
			try {
				const companies = this.$store.state.auth?.user?.companies;
				if (Array.isArray(companies)) {
					companies.forEach((company) => {
						company?.profiles.forEach((profile) => {
							if (
								profile?.role?.name == 'Administrador' ||
								profile?.role?.name == 'Super Admin'
							) {
								isAdmin = true;
							}
						});
					});
				}
			} catch (error) {}

			return isAdmin;
		},

		breadcrumbs: function () {
			return [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Detalle de documento',
					disabled: true,
					href: '/',
				},
			];
		},

		dataQR() {
			let url = null;
			if (this.contract) {
				let urlx = new URL(window.location.origin + '/dashboard/file/read');
				// urlx.searchParams.set('url', this.contract?.file);
				urlx.searchParams.set('url', this.contractFile);
				url = urlx.toString();
			}
			return url;
		},
	},
	created() {
		this.loadContract();
		this.getUsersActive();
	},
	watch: {},
	methods: {
		...mapActions('contract', ['getContract']),
		...mapActions('security', ['getUsersActive']),
		...mapActions('document', ['getTemplate']),

		async loadContract() {
			this.isLoading = true;
			await this.getContract(this.$route.params.documentId);
			this.getFormat();
			this.isLoading = false;
		},

		formatDate(date) {
			moment.locale('es');
			return date ? moment(date).format('LL') : null;
		},

		getUserName(id) {
			const user = (this.usersActive || []).find((user) => user.id == id);
			return user?.fullName || '';
		},

		getFormat() {
			if (this.contract?.documentType?.flag_contract_form) {
				this.getTemplate(this.contract?.documentType?.id);
			}
		},

		openSheetAddendumDialog(document) {
			this.sheetAddendumFields = document.contractDocumentForm;
			this.sheetAddendumDialog = true;
			this.currentDocument = document;
		},
	},
	destroyed() {},
	components: {
		AddDocumentDialog,
		AddVersionDialog,
		ContractSheetDialog,
		AddCommentDialog,
		QrcodeVue,
		AddDirectAccessDialog,
		ResponsiblesSection,
		DeleteDocumentBtn,
		DeleteFileBtn,
		DeleteVersionBtn,
		UpdateTitleDialog,
		DisableContractBtn,
	},
};
